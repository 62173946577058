import {
  EMAIL_REGEX,
  NUMERIC_REGEX_OR_EMPTY_STRING,
} from "../Constants/DataConstants";

//returns true for field value of empty string and only white spaces.
export const EmptyField = (field: unknown): boolean => {
  return typeof field === "string" && field.trim() === "";
};
//returns true for field length greater than maxlength.
export const MaxLengthExceeded = (field: string, maxLength: number) => {
  return field.length > maxLength;
};
//returns true for valid email address.
export const ValidEmailAddress = (field: string) => {
  if (EMAIL_REGEX.test(field)) {
    return true;
  } else return false;
};

export const NumericPattern = (field: string) => {
  if (NUMERIC_REGEX_OR_EMPTY_STRING.test(field)) {
    return true;
  } else return false;
};
