import { Button, createTheme, TextField, ThemeProvider } from "@mui/material";
import { purple, pink } from "@mui/material/colors";
import {
  InitialPageRenderType,
  UserSubmission,
} from "../Types/UserSubmissionType";
import { ChangeEvent, useState } from "react";
import {
  EMAIL_REGEX,
  INIT_TESTIMONY_PRAYER_DATA,
  INITIAL_PAGE_RENDER_DATA,
  NUMERIC_REGEX_OR_EMPTY_STRING,
  SUBMISSION_SUCCESS_MESSAGE,
} from "../Constants/DataConstants";
import moment from "moment";
import { insertSubmission } from "../ApiServices/SubmissionApis";
import GetModal from "../Utilities/Utilities";
import ApiResponseModal from "./ApiResponseModal";
import {
  EmptyField,
  MaxLengthExceeded,
  NumericPattern,
  ValidEmailAddress,
} from "../Utilities/Validation";

const colorTheme = createTheme({
  palette: {
    primary: {
      main: purple["500"],
    },
  },
});

export default function Form(props: {
  requestType: string;
  formData: UserSubmission;
  setFormData: React.Dispatch<React.SetStateAction<UserSubmission>>;
}) {
  const [initialPageRender, setInitialPageRender] =
    useState<InitialPageRenderType>(INITIAL_PAGE_RENDER_DATA);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState("");

  //handles the change event of the form.
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setFormData((prevState: any) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  //handles the validation of the form and then.
  const handleSubmit = () => {
    setInitialPageRender({
      first_name: false,
      last_name: false,
      phone: false,
      email: false,
      location: false,
      submission: false,
    });
    //check validation of form here
    //...
    let isValid = true;
    //set state for submission_date and for submission_type
    props.formData.submission_date = moment().format("MM/DD/YYYY HH:mm:ss");
    props.formData.submission_type = props.requestType;
    for (const [key, value] of Object.entries(props.formData)) {
      if (
        key !== "phone" &&
        key !== "submission" &&
        (EmptyField(value) || MaxLengthExceeded(value, CONTACT_INFO_MAX_LENGTH))
      ) {
        isValid = false;
        break;
      }
      if (
        key === "phone" &&
        MaxLengthExceeded(value, PHONE_MAX_LENGTH) &&
        NumericPattern(value)
      ) {
        isValid = false;
        break;
      }
      if (
        key === "submission" &&
        MaxLengthExceeded(value, MESSAGE_MAX_LENGTH)
      ) {
        isValid = false;
        break;
      }
    }
    //if form passes validation, make api call.
    if (isValid) {
      console.log("passed all validation.");
      setIsLoading(true);
      GetModal("apiResponseModal", "show");
      //call insertSubmission lambda
      insertSubmission(props.formData).then((response) => {
        if (response.data.status === "success") {
          setResponseMessage(SUBMISSION_SUCCESS_MESSAGE);
        }
        setIsLoading(false);
        //empty the form after a successful submission.
        props.setFormData(INIT_TESTIMONY_PRAYER_DATA);
      });
    }
  };
  const CONTACT_INFO_MAX_LENGTH = 50;
  const MESSAGE_MAX_LENGTH = 3500;
  const PHONE_MAX_LENGTH = 13;

  return (
    <ThemeProvider theme={colorTheme}>
      <div className="row">
        <div className="col-md-6 col-12 justify-content-center">
          <div className="row mt-4">
            <TextField
              name="first_name"
              value={props.formData.first_name}
              label="First Name *"
              variant="outlined"
              style={{ width: "100%", borderColor: "#880e4f" }}
              onFocus={() =>
                setInitialPageRender((prevState) => {
                  return { ...prevState, first_name: false };
                })
              }
              onChange={handleChange}
              error={
                (!initialPageRender.first_name &&
                  EmptyField(props.formData.first_name)) ||
                (!initialPageRender.first_name &&
                  MaxLengthExceeded(
                    props.formData.first_name,
                    CONTACT_INFO_MAX_LENGTH
                  ))
              }
              helperText={
                !initialPageRender.first_name &&
                EmptyField(props.formData.first_name)
                  ? "You must enter your first name."
                  : !initialPageRender.first_name &&
                    MaxLengthExceeded(
                      props.formData.first_name,
                      CONTACT_INFO_MAX_LENGTH
                    )
                  ? "You have exceeded the maximum number of characters."
                  : null
              }
            />
          </div>
          <div className="row mt-4">
            <TextField
              type="text-area"
              name="last_name"
              value={props.formData.last_name}
              label="Last Name *"
              variant="outlined"
              onChange={handleChange}
              onFocus={() =>
                setInitialPageRender((prevState) => {
                  return { ...prevState, last_name: false };
                })
              }
              error={
                (!initialPageRender.last_name &&
                  EmptyField(props.formData.last_name)) ||
                (!initialPageRender.last_name &&
                  MaxLengthExceeded(
                    props.formData.last_name,
                    CONTACT_INFO_MAX_LENGTH
                  ))
              }
              helperText={
                !initialPageRender.last_name &&
                EmptyField(props.formData.last_name)
                  ? "You must enter your last name."
                  : !initialPageRender.last_name &&
                    MaxLengthExceeded(
                      props.formData.last_name,
                      CONTACT_INFO_MAX_LENGTH
                    )
                  ? "You have exceeded the maximum number of characters."
                  : null
              }
            />
          </div>
          <div className="row mt-4">
            <TextField
              name="email"
              value={props.formData.email}
              label="Email *"
              variant="outlined"
              className="w-100"
              onChange={handleChange}
              onFocus={() =>
                setInitialPageRender((prevState) => {
                  return { ...prevState, email: false };
                })
              }
              error={
                (!initialPageRender.email &&
                  EmptyField(props.formData.email)) ||
                (!initialPageRender.email &&
                  MaxLengthExceeded(
                    props.formData.email,
                    CONTACT_INFO_MAX_LENGTH
                  )) ||
                (!initialPageRender.email &&
                  !ValidEmailAddress(props.formData.email))
              }
              helperText={
                !initialPageRender.email && EmptyField(props.formData.email)
                  ? "You must enter your your email address."
                  : !initialPageRender.email &&
                    MaxLengthExceeded(
                      props.formData.email,
                      CONTACT_INFO_MAX_LENGTH
                    )
                  ? "You have exceeded the maximum number of characters."
                  : !initialPageRender.email &&
                    !ValidEmailAddress(props.formData.email)
                  ? "Your email address is invalid."
                  : null
              }
            />
          </div>
          <div className="row mt-4">
            <TextField
              name="phone"
              value={props.formData.phone}
              label="Phone"
              variant="outlined"
              className="w-100"
              onChange={handleChange}
              onFocus={() =>
                setInitialPageRender((prevState) => {
                  return { ...prevState, phone: false };
                })
              }
              error={
                (!initialPageRender.phone &&
                  !NumericPattern(props.formData.phone)) ||
                (!initialPageRender.phone &&
                  MaxLengthExceeded(props.formData.phone, PHONE_MAX_LENGTH))
              }
              helperText={
                !initialPageRender.phone &&
                !NumericPattern(props.formData.phone)
                  ? "You entered an invalid phone number."
                  : !initialPageRender.phone &&
                    MaxLengthExceeded(props.formData.phone, PHONE_MAX_LENGTH)
                  ? "You have exceeded the maximum number of characters."
                  : null
              }
            />
          </div>
          <div className="row mt-4">
            <TextField
              name="location"
              value={props.formData.location}
              label="Location"
              variant="outlined"
              onChange={handleChange}
              onFocus={() =>
                setInitialPageRender((prevState) => {
                  return { ...prevState, location: false };
                })
              }
              error={
                (!initialPageRender.location &&
                  EmptyField(props.formData.location)) ||
                (!initialPageRender.location &&
                  MaxLengthExceeded(
                    props.formData.location,
                    CONTACT_INFO_MAX_LENGTH
                  ))
              }
              helperText={
                !initialPageRender.location &&
                EmptyField(props.formData.location)
                  ? "You must enter your location."
                  : !initialPageRender.location &&
                    MaxLengthExceeded(
                      props.formData.location,
                      CONTACT_INFO_MAX_LENGTH
                    )
                  ? "You have exceeded the maximum number of characters."
                  : null
              }
            />
          </div>
        </div>
        <div className="col-md-6 col-12 mt-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10 ">
              <TextField
                name="submission"
                multiline
                minRows={16}
                maxRows={16}
                placeholder={props.requestType}
                style={{ width: "100%" }}
                onChange={handleChange}
                onFocus={() =>
                  setInitialPageRender((prevState) => {
                    return { ...prevState, submission: false };
                  })
                }
                error={
                  (!initialPageRender.submission &&
                    EmptyField(props.formData.submission)) ||
                  (!initialPageRender.submission &&
                    MaxLengthExceeded(
                      props.formData.submission,
                      MESSAGE_MAX_LENGTH
                    ))
                }
                helperText={
                  !initialPageRender.submission &&
                  EmptyField(props.formData.submission)
                    ? "This field is required."
                    : !initialPageRender.submission &&
                      MaxLengthExceeded(
                        props.formData.submission,
                        MESSAGE_MAX_LENGTH
                      )
                    ? "You have exceeded the maximum number of characters."
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-center mt-2">
          <Button
            className="text-white"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <ApiResponseModal message={responseMessage} isLoading={isLoading} />
    </ThemeProvider>
  );
}
