import axios from "axios";
import {
  INSERT_SUBMISSION_URL,
  GET_SUBMISSION_URL,
} from "../Constants/UrlConstants";
import {
  TypeofSubmissionType,
  UserSubmission,
} from "../Types/UserSubmissionType";

export async function insertSubmission(formData: UserSubmission) {
  return await axios.post(INSERT_SUBMISSION_URL, formData);
}

export async function getSubmission(submissionType: TypeofSubmissionType) {
  return await axios.post(GET_SUBMISSION_URL, submissionType);
}
